import { SM3Digest } from '@/utils/sign/sm3-1.0'
import { Hex } from '@/utils/sign/hex'

export function getSign (params) {
  return new Promise((resolve) => {
    const content = 'app_id=' + params.app_id + '&biz_content=' + JSON.parse(JSON.stringify(params.biz_content)) + '&charset=' + params.charset + '&interface_id=' + params.interface_id + '&origin=' + params.origin + '&timestamp=' + params.timestamp + '&version=' + params.version
    // 这一步是先将输入数据转成utf-8编码的字节流，然后再转成16进制可见字符
    const dataBy = Hex.utf8StrToBytes(content)
    const sm3 = new SM3Digest()
    sm3.update(dataBy, 0, dataBy.length)// 数据很多的话，可以分多次update
    const sm3Hash = sm3.doFinal()// 得到的数据是个byte数组
    const sm3HashHex = Hex.encode(sm3Hash, 0, sm3Hash.length)// 编码成16进制可见字符
    resolve(sm3HashHex.toLowerCase())
  })
}

/* 命名空间 */

// 默认命名空间
export const defaultNamespace = 'wa'

/**
 * BEM生成方法
 * @param {string} namespace - 命名空间
 * @param {string} block - 块
 * @param {string} blockSuffix - 块后缀
 * @param {string} element - 元素
 * @param {string} modifier - 修改器
 * @returns {string}
 * @private
 */
const _bem = (namespace, block, blockSuffix, element, modifier) => {
  // 默认是 Block
  let cls = `${namespace}-${block}`
  // 如果存在 Block 后缀，也就是 Block 里面还有 Block
  if (blockSuffix) {
    cls += `-${blockSuffix}`
  }
  // 如果存在元素
  if (element) {
    cls += `__${element}`
  }
  // 如果存在修改器
  if (modifier) {
    cls += `--${modifier}`
  }
  return cls
}

/**
 * 命名空间Hook
 * @param {string} block - 块
 */
export const useNamespace = (block) => {
  // 命名空间
  const namespace = defaultNamespace

  /**
   * 创建块
   * @param {string} [blockSuffix = ''] - 块后缀
   * @returns {string}
   *
   * @example
   * const ns = useNamespace('switch');
   * ns.b();
   * // => wa-switch
   *
   * @example
   * const ns = useNamespace('switch');
   * ns.b('circle');
   * // => wa-switch-circle
   */
  const b = (blockSuffix = '') =>
    _bem(namespace, block, blockSuffix, '', '')

  /**
   * 创建元素
   * @param {string} element - 元素名称
   * @returns {string}
   *
   * @example
   * const ns = useNamespace('menuItem');
   * ns.e('container');
   * // => wa-menuItem__container
   */
  const e = (element) =>
    element ? _bem(namespace, block, '', element, '') : ''

  /**
   * 创建块修改器
   * @param {string} modifier - 修改器
   * @returns {string}
   *
   * @example
   * const ns = useNamespace('button');
   * ns.m('success');
   * // => wa-button--success
   */
  const m = (modifier) =>
    modifier ? _bem(namespace, block, '', '', modifier) : ''

  /**
   * 创建后缀块元素
   * @param {string} blockSuffix - 块后缀
   * @param {string} element - 元素
   * @returns {string}
   *
   * @example
   * const ns = useNamespace('table');
   * ns.be('header', 'tr');
   * // => wa-table-header__tr
   */
  const be = (blockSuffix, element) =>
    blockSuffix && element
      ? _bem(namespace, block, blockSuffix, element, '')
      : ''

  /**
   * 创建元素修改器
   * @param {string} element - 元素
   * @param {string} modifier - 修改器
   * @returns {string}
   *
   * @example
   * const ns = useNamespace('page');
   * ns.em('button', 'prev');
   * // => wa-page__button--prev
   */
  const em = (element, modifier) =>
    element && modifier
      ? _bem(namespace, block, '', element, modifier)
      : ''

  /**
   * 创建块后缀修改器
   * @param {string} blockSuffix - 块后缀
   * @param {string} modifier - 修改器
   * @returns {string}
   *
   * @example
   * const ns = useNamespace('rate');
   * ns.bm('halfStar', 'left');
   * // => wa-rate-halfStar--left
   */
  const bm = (blockSuffix, modifier) =>
    blockSuffix && modifier
      ? _bem(namespace, block, blockSuffix, '', modifier)
      : ''

  /**
   * 创建块元素修改器
   * @param {string} blockSuffix - 块前缀
   * @param {string} element - 元素
   * @param {string} modifier - 修改器
   * @returns {string}
   *
   * @example
   * const ns = useNamespace('numberInput');
   * ns.bem('insideHandle', 'button', 'up');
   * // => wa-numberInput-insideHandle__button--up
   */
  const bem = (blockSuffix, element, modifier) =>
    blockSuffix && element && modifier
      ? _bem(namespace, block, blockSuffix, element, modifier)
      : ''

  return {
    namespace,
    b,
    e,
    m,
    be,
    em,
    bm,
    bem
  }
}

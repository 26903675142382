import { axios } from '@/utils/request'

export function get (path, params, token) {
  return axios({
    url: path,
    method: 'get',
    headers: {
      'access-token': token
    },
    params
  })
}

export function post (path, params, token) {
  return axios({
    url: path,
    method: 'post',
    data: params,
    headers: {
      'Content-Type': 'application/json',
      'access-token': token
    }
  })
}

export function postFormData (path, params, token) {
  return axios({
    url: path,
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data', 'access-token': token },
    data: params
  })
}

export function postInvoice (path, params, token) {
  return axios({
    url: path,
    method: 'post',
    data: params,
    headers: {
      'Content-Type': 'application/json',
      'access-token': token,
      'origins': '0'
    }
  })
}

export default {
  get,
  post,
  postFormData,
  postInvoice
}

<template>
  <a-modal
    class="wkl-translate"
    :visible="show"
    :closable="true"
    :footer="false"
    @cancel="close"
    centered
    destroyOnClose>
    <div class="wkl-translate-language">
      <a-select
        class="wkl-translate-language__select"
        v-model="sourceLanguage"
        placeholder="请选择语言"
        @change="sourceLanguageChange">
        <a-select-option
          v-for="(item, index) in sourceLanguageList"
          :value="item.abbreviation"
          :key="index">{{ item.name }}</a-select-option>
      </a-select>
      <a-select
        class="wkl-translate-language__select"
        v-model="targetLanguage"
        placeholder="请选择语言"
        :key="targetLanguageKey">
        <a-select-option
          v-for="(item, index) in targetLanguageList"
          :value="item.abbreviation"
          :disabled="item.disabled"
          :key="index">{{ item.name }}</a-select-option>
      </a-select>
    </div>
    <a-row class="wkl-translate-textarea">
      <a-col span="11">
        <a-textarea v-model="translateContent" placeholder="请输入需要翻译的内容" :rows="18" />
      </a-col>
      <a-col span="11" offset="2">
        <a-textarea v-model="translateResult" placeholder="翻译结果" :rows="18" readOnly/>
        <a-tooltip>
          <template slot="title">
            复制
          </template>
          <img
            class="copy"
            :data-clipboard-text="translateResult"
            @click="copyText()"
            src="@/assets/mainHeader/copy.png"
            width="20"
            height="20"
            alt="复制">
        </a-tooltip>
      </a-col>

    </a-row>
    <a-button
      class="wkl-translate-btn"
      type="primary"
      @click="translate"
      block>翻译</a-button>
  </a-modal>
</template>
<!-- 翻译功能组件 -->
<script>
import ClipboardJS from 'clipboard'
import { requestInterface } from '@/api/wklAPI'

export default {
  name: 'WklTranslate',
  watch: {
    show (value) {
      if (value === true) {
        requestInterface({
          interfaceId: 'translategetAllNames'
        }).then(res => {
          if (!res.success) return

          this.sourceLanguageList = res.data.language.map(item => {
            return {
              ...item,
              disabled: false
            }
          })
          this.targetLanguageList = res.data.language.map(item => {
            return {
              ...item,
              disabled: false
            }
          })

          this.sourceLanguage = this.sourceLanguageList[0].abbreviation
          this.targetLanguage = this.targetLanguageList[1].abbreviation
        })
      }
    }
  },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      sourceLanguageList: [], // 当前语种列表
      targetLanguageList: [], // 目标语种列表
      targetLanguageKey: 0, // 刷新标识
      sourceLanguage: '', // 当前语种
      targetLanguage: '', // 目标语种
      translateContent: '', // 翻译内容
      translateResult: '' // 翻译结果
    }
  },
  mounted () {
    this.createClipboard()
  },
  methods: {
    /**
     * 创建粘贴板
     * @return {ClipboardJS}
     */
    createClipboard() {
      return new ClipboardJS('.copy')
    },
    /**
     * 当前语种更变
     * @param value
     */
    sourceLanguageChange (value) {
      if (value === 'cn') {
        this.targetLanguageList.forEach(item => {
          item.disabled = false
        })
        return
      }
      this.targetLanguageList.forEach(item => {
        if (item.abbreviation !== 'cn') item.disabled = true
        this.targetLanguage = this.targetLanguageList[0].abbreviation
      })
    },
    /**
     * 翻译
     */
    translate () {
      if (!this.translateContent) {
        this.$message.info('请输入需要翻译的内容')
        return
      }
      if (this.sourceLanguage === this.targetLanguage) {
        this.$message.info('当前语言与目标语言相同，请切换语言')
        return
      }
      this.translateResult = undefined
      requestInterface({
        interfaceId: 'translatetranslateContent',
        params: {
          content: this.translateContent,
          sourceLanguage: this.sourceLanguage,
          targetLanguage: this.targetLanguage
        }
      }).then(res => {
        if (!res.success) {
          this.$message.info(res.message)
          return
        }
        this.translateResult = res.data.content
      })
    },
    /**
     * 复制内容到剪贴板
     */
    copyText () {
      this.$message.success('复制成功')
    },
    /**
     * 关闭对话框
     */
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .ant-modal {
  width: 800px !important;
}
.wkl-translate {
  &-language {
    display: flex;
    justify-content: space-between;
    margin-top: 32px;
    margin-bottom: 32px;

    &__select {
      width: 344px;
    }
  }

  &-textarea {
    margin-bottom: 32px;
  }
  .copy {
    position: absolute;
    right: 25px;
    bottom: 5px;
    cursor: pointer;
  }
}
</style>

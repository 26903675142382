<template>
  <footer class="wkl-mainFooter">
    <div class="wkl-mainFooter__container">
      <div class="wkl-mainFooter-logo">
        <img
          class="wkl-mainFooter-logo__img"
          src="@/assets/mainFooter/footerLogo.png"
          alt="logo" />
      </div>
      <div class="wkl-mainFooter-info">
        <div class="wkl-mainFooter-links">
          <span class="wkl-mainFooter-links__title">友情链接</span>
          <a
            class="wkl-mainFooter-links__linkItem"
            href="https://www.fmprc.gov.cn/"
            target="_blank"
            rel="noopener">中华人民共和国外交部</a>
          <a
            class="wkl-mainFooter-links__linkItem"
            href="http://cs.mfa.gov.cn/"
            target="_blank"
            rel="noopener">中国领事服务网</a>
        </div>
        <p class="wkl-mainFooter-copyright">
          版权所有：世界知识出版社有限公司
          <a
            class="wkl-mainFooter-copyright__link"
            href="https://beian.miit.gov.cn/#/Integrated/index"
            target="_blank"
            rel="noopener">
            京ICP备09026639号-1
          </a>
          <img
            class="wkl-mainFooter-copyright__badge"
            src="@/assets/mainFooter/footerBadge.png"
            alt="logo" />
          <a
            class="wkl-mainFooter-copyright__links"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010102001872"
            target="_blank"
            rel="noopener">
            京公网安备 11010102001872号
          </a>
        </p>
      </div>
    </div>
  </footer>
</template>
<!-- 通用底部 -->
<script>
export default {
  name: 'MainFooter'
}
</script>

<style lang="less" scoped>
@import "@/style/commonStyle.less";
.wkl-mainFooter {
  width: 100%;
  background-image: url(../assets/mainFooter/footerBackground.png);
  background-repeat: no-repeat;
  background-size: 100% 140px;
  background-position-x: center;

  &__container {
    .container();
    display: flex;
    height: 140px;
  }

  // 底部logo
  &-logo {
    margin-right: 83px;

    &__img {
      width: 377px;
      height: 140px;
    }
  }

  &-info {
    display: flex;
    flex-direction: column;
    padding-top: 40px;
  }

  // 友情链接
  &-links {
    display: flex;
    width: 407px;
    height: 25px;
    padding-left: 7px;
    background-image: url(../assets/mainFooter/footerLinks.png);
    background-repeat: no-repeat;
    background-size: 407px 25px;
    margin-bottom: 9px;

    &__title {
      width: 78px;
      color: white;
      font-size: 16px;
      font-family: "PingFang SC", sans-serif;
    }

    &__linkItem {
      color: rgba(226, 29, 23, 1);
      font-size: 16px;
      font-family: "PingFang SC", sans-serif;
      font-weight: 400;
      letter-spacing: 0;

      &:nth-child(2) {
        margin: 0 22px 0 12px;
      }
    }

  }

  // 版权信息
  &-copyright {
    display: flex;
    align-items: center;
    font-family: "PingFang SC", sans-serif;
    font-size: 16px;
    color: #fff;
    margin-bottom: 7px;

    &__badge {
      width: 20px;
      height: 20px;
      margin: 0 8px;
    }

    &__link {
      margin-left: 10px;
      color: #fff;
    }
    &__links {
      color: #fff;
    }
  }

  // 工作信息
  &-workInfo {
    display: flex;
  }

  // 电话
  &-tel {
    font-family: "PingFang SC", sans-serif;
    font-size: 16px;
    color: #fff;
    margin-right: 53px;
  }

  // 工作时间
  &-workTime {
    font-family: "PingFang SC", sans-serif;
    font-size: 16px;
    color: #fff;
  }

}
</style>

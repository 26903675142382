// 工具方法

import { commonAPI } from '@/api/commonAPI'

/**
 * 获取加密公钥
 * */
function getPublicKey () {
  const key = sessionStorage.getItem('_pk_')
  if (key) return key
  commonAPI.getPublicKey().then(res => {
    sessionStorage.setItem('_pk_', res)
    return res
  })
}

/**
 * 处理时间
 * @param dateValue 需要处理的时间值
 * @returns {Object} dateData = {year, month, day, hour, minute, second}
 * */
function handleDate (dateValue) {
  const date = new Date(dateValue)

  return {
    year: date.getFullYear(),
    month: addZero(date.getMonth() + 1),
    day: addZero(date.getDate()),
    hour: addZero(date.getHours()),
    minute: addZero(date.getMinutes()),
    second: addZero(date.getSeconds())
  }

  /**
   * 补0
   * */
  function addZero (val) {
    return val < 10 ? `0${val}` : val
  }
}

/**
 * 获取时间字符串
 * @param dateValue 需要处理的时间值
 * @param {String} type 字符串类型
 * @returns {String}
 * */
function getDateString (dateValue, type) {
  const date = handleDate(dateValue)
  switch (type) {
    case 'yyyy-mm-dd': {
      return `${date.year}-${date.month}-${date.day}`
    }
    case 'yyyy-mm-dd hh:mm:ss': {
      return `${date.year}-${date.month}-${date.day} ${date.hour}:${date.minute}:${date.second}`
    }
    default: {
      return ''
    }
  }
}

/**
 * 检索高亮
 * @param {String} value 需要高亮的字符串
 * @param {String} searchKeyWord 检索条件
 * @returns {String}
 * */
function searchHighlight (value, searchKeyWord) {
  if (value === null || value === undefined) value = ''
  const str = value
  // searchKeyWord = searchKeyWord.replace(/\s/g, '')
  const reg = new RegExp('([' + searchKeyWord + '])', 'g')
  let result = {}
  let currentIndex = 0
  const returnData = []
  while ((result = reg.exec(str))) {
    returnData.push(str.slice(currentIndex, result.index))
    returnData.push('<font color="#E21D17">' + result[1] + '</font>')
    currentIndex = result.index + result[0].length
  }
  if (str.length > currentIndex) {
    returnData.push(str.slice(currentIndex, str.length))
  }
  return returnData.join('')
}

// 检索手机正则验证
function searchRegMobile (value) {
  // 验证手机号的正则表达式
  const regMobile = /^(0|86|17951)?1[3-9]\d{9}$/
  return regMobile.test(value)
}

/**
 * 节流方法
 * @param {function} func 需要进行节流执行的方法
 * @param {string | number} delay 节流的时间间隔
 * */
function throttle (func, delay) {
  const delayTime = delay || 1000
  const previousDate = new Date()
  // 初始化一个时间，也作为高频率事件判断事件间隔的变量。
  let previous = previousDate.getTime()

  return function (args) {
    const context = this
    const nowDate = new Date()
    const now = nowDate.getTime()
    if (now - previous >= delayTime) { // 如果本次触发和上次触发的时间间隔超过设定的时间
      func.call(context, args) // 就执行事件处理函数 （eventHandler）
      previous = now // 然后将本次的触发时间，作为下次触发事件的参考时间。
    }
  }
}

/**
 * 验证码
 */
// 得到随机的颜色值
function randomColor () {
  const r = Math.floor(getRandom() * 256)
  const g = Math.floor(getRandom() * 256)
  const b = Math.floor(getRandom() * 256)
  return 'rgb(' + r + ',' + g + ',' + b + ')'
}

function createCode (value) {
  const canvasWidth = document.getElementById('canvas').width
  const canvasHeight = document.getElementById('canvas').height
  const canvas = document.getElementById('canvas') // 获取到canvas的对象，演员
  const context = canvas.getContext('2d') // 获取到canvas画图的环境，演员表演的舞台
  canvas.width = canvasWidth
  canvas.height = canvasHeight
  const aCode = value.split('')
  for (let i = 0; i < 4; i++) {
    // 这里的for循环可以控制验证码位数（如果想显示6位数，4改成6即可）
    // var deg = getRandom() * 30 * Math.PI / 180//产生0~30之间的随机弧度
    const deg = getRandom() - 0.5 // 产生一个随机弧度
    const txt = aCode[i] // 得到随机的一个内容
    const x = 15 + i * 18 // 文字在canvas上的x坐标
    const y = 30 + getRandom() * 8 // 文字在canvas上的y坐标
    context.font = 'bold 23px 微软雅黑'
    context.translate(x, y)
    context.rotate(deg)
    context.fillStyle = randomColor()
    context.fillText(txt, 0, 0)
    context.rotate(-deg)
    context.translate(-x, -y)
  }
  for (let i = 0; i <= 5; i++) {
    // 验证码上显示线条
    context.strokeStyle = randomColor()
    context.beginPath()
    context.moveTo(
      getRandom() * canvasWidth,
      getRandom() * canvasHeight
    )
    context.lineTo(
      getRandom() * canvasWidth,
      getRandom() * canvasHeight
    )
    context.stroke()
  }
  for (let i = 0; i <= 30; i++) {
    // 验证码上显示小点
    context.strokeStyle = randomColor()
    context.beginPath()
    const x = getRandom() * canvasWidth
    const y = getRandom() * canvasHeight
    context.moveTo(x, y)
    context.lineTo(x + 1, y + 1)
    context.stroke()
  }
}

/**
 * 处理图片链接
 * @param {string} url - 图片链接
 * @return {string}
 */
function handleImgUrl (url) {
  if (url === '' || url === null || typeof url === 'undefined') {
    return '/cms_files/filemanager/1741463117/picture/202211/0c11d2e88a234bd4b00e9d67daa357cf.png'
  } else if (url.indexOf('http:') === -1 && url.indexOf('https:') === -1) {
    return '/bjszspub' + url
  }
}

/**
 * base64加密
 * @param {string} data - 需要加密的数据
 * @return {string}
 */
function encodeBase64 (data) {
  return window.btoa(encodeURIComponent(data))
}

/**
 * base64解密
 * @param {string} data - 需要解密的数据
 * @return {string}
 */
function decodeBase64 (data) {
  return decodeURIComponent(window.atob(data))
}

// 生成随机数
function getRandom(min = 0, max = 100) {
  const randomArray = new Uint32Array(1)
  window.crypto.getRandomValues(randomArray)
  return (Math.floor((randomArray[0] / (Math.pow(2, 32) - 1)) * (max - min + 1)) + min) / 100
}

export {
  getPublicKey,
  handleDate,
  getDateString,
  searchHighlight,
  searchRegMobile,
  throttle,
  createCode,
  handleImgUrl,
  encodeBase64,
  decodeBase64,
  getRandom
}

import { axios } from '@/utils/request'
import { post, postFormData } from '@/api/mutations'

const baseUrl = '/hanweb-bjszs-web-server/front'

const apiUrl = {
  // 通用
  getPublicKey: `/hanweb-bjszs-web-server/interface/security/queryKey`, // 获取公钥
  getUserInfo: `${baseUrl}/user/userInfo`, // 获取用户信息
  sendCode: `${baseUrl}/sms/sendCode`, // 发送短信验证码
  getPreventRepeatToken: `${baseUrl}/token/getPreventRepeatToken`, // 获取订单页面防重令牌
  getPayOrderToken: `${baseUrl}/token/getPayOrderToken`, // 获取支付令牌

  // 个人空间
  getAddress: `${baseUrl}/address/findInfo`, // 获取地址信息
  addAddress: `${baseUrl}/address/add`, // 新增地址信息
  editAddress: `${baseUrl}/address/modify`, // 修改地址信息
  deleteAddress: `${baseUrl}/address/delete`, // 删除地址信息

  // 钱包
  getBalance: `${baseUrl}/wallet/findBalance`, // 获取余额
  bindRechargeCard: `${baseUrl}/wallet/bindPointCard`, // 绑定充值卡
  modifyPaymentPassword: `${baseUrl}/wallet/modifyPayPwd`, // 修改支付密码

  // 我的订单
  getOrderTotal: `${baseUrl}/order/orderCount`, // 获取订单总数
  getAccumulatedConsumption: `${baseUrl}/order/consumeTotal`, // 获取累计消费
  getOrderList: `${baseUrl}/order/findOrderNoPay`, // 获取订单列表
  findInfoByState: `${baseUrl}/order/findInfoByState`, // 获取订单列表
  deleteOrder: `${baseUrl}/order/deleteOrder`, // 获取订单列表

  // 购物车
  getshoppingCar: `${baseUrl}/cart/getCart`, // 获取购物车所有商品信息
  addCartItem: `${baseUrl}/cart/addCartItem`, // 添加商品到购物车
  deleteItems: `${baseUrl}/cart/deleteItems`, // 删除购物车商品
  submitOrder: `${baseUrl}/order/submitOrder`, // 提交订单
  nativePay: `${baseUrl}/pay/nativePay`, // 扫码支付
  queryPaymentResult: `${baseUrl}/pay/queryPaymentResult`, // 支付结果

  // 分类列表详情
  classList: `${baseUrl}/booksClass/listClass`, // 分类列表
  shopList: `${baseUrl}/bookCommodity/list`, // 商品列表
  shopDetail: `${baseUrl}/bookCommodity/details` // 商品详情
}

export const commonAPI = {
  // 通用
  /**
   * 获取地址信息
   * */
  getPublicKey (params, token) {
    return post(apiUrl.getPublicKey, params, token)
  },
  /**
   * 获取用户信息
   */
  getUserInfo (params) {
    return post(apiUrl.getUserInfo, params)
  },
  /**
   * 发送短信验证码
   */
  sendCode (params) {
    return postFormData(apiUrl.sendCode, params)
  },

  // 个人空间
  /**
   * 获取地址信息
   * */
  getAddress (params, token) {
    return post(apiUrl.getAddress, params, token)
  },
  /**
   * 新增地址信息
   * */
  addAddress (params, token) {
    return postFormData(apiUrl.addAddress, params, token)
  },
  /**
   * 修改地址信息
   * */
  editAddress (params, token) {
    return postFormData(apiUrl.editAddress, params, token)
  },
  /**
   * 删除地址信息
   * */
  deleteAddress (params, token) {
    return post(apiUrl.deleteAddress, params, token)
  },

  // 钱包
  /**
   * 获取余额
   */
  getBalance (params) {
    return post(apiUrl.getBalance, params)
  },
  /**
   * 绑定充值卡
   */
  bindRechargeCard (params) {
    return axios({
      url: apiUrl.bindRechargeCard,
      method: 'post',
      data: params,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  /**
   * 修改支付密码
   * */
  modifyPaymentPassword (params) {
    return postFormData(apiUrl.modifyPaymentPassword, params)
  },

  // 我的订单
  /**
   * 获取订单总数
   * */
  getOrderTotal (params) {
    return post(apiUrl.getOrderTotal, params)
  },
  /**
   * 获取累计消费
   */
  getAccumulatedConsumption (params) {
    return post(apiUrl.getAccumulatedConsumption, params)
  },
  /**
   * 获取订单列表
   */
  getOrderList (params) {
    return post(apiUrl.getOrderList, params)
  },
  /**
   * 获取订单列表
   */
  findInfoByState (params) {
    return post(apiUrl.findInfoByState, params)
  },
  /**
   * 删除订单
   */
  deleteOrder (params) {
    return postFormData(apiUrl.deleteOrder, params)
  },

  // 购物车
  /**
   * 获取购物车所有商品信息
   */
  getshoppingCar (params) {
    return post(apiUrl.getshoppingCar, params)
  },
  /**
   * 添加商品到购物车
   */
  addCartItem (params) {
    return post(apiUrl.addCartItem, params)
  },
  /**
   * 删除购物车商品
   */
  deleteItems (params) {
    return axios({
      url: apiUrl.deleteItems,
      method: 'POST',
      data: params,
      headers: {
        'Content-Type': 'application/json'
      }
    })
  },
  /**
   * 获取订单页面防重令牌
   */
  getPreventRepeatToken (params) {
    return post(apiUrl.getPreventRepeatToken, params)
  },
  /**
   * 提交订单
   */
  submitOrder (params, token) {
    return axios({
      url: apiUrl.submitOrder,
      method: 'POST',
      data: params,
      headers: {
        'Content-Type': 'multipart/form-data',
        'ditto-token': token
      }
    })
  },
  /**
   * 获取支付令牌
   */
  getPayOrderToken (params) {
    return postFormData(apiUrl.getPayOrderToken, params)
  },
  /**
   * 扫码支付
   */
  nativePay (params, token) {
    return axios({
      url: apiUrl.nativePay,
      method: 'POST',
      data: params,
      headers: {
        'Content-Type': 'multipart/form-data',
        'ditto-token': token
      }
    })
  },
  // 支付结果
  queryPaymentResult (params) {
    return postFormData(apiUrl.queryPaymentResult, params)
  },
  // 分类列表
  getClassLists (params) {
    return post(apiUrl.classList, params)
  },
  // 商品列表
  getShopLists (params) {
    return axios({
      url: apiUrl.shopList,
      method: 'POST',
      data: params,
      headers: {
        'Content-Type': 'application/json'
      }
    })
  },
  // 获取商品详情
  getshopDetail (params) {
    return postFormData(apiUrl.shopDetail, params)
  }
}

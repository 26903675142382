import { axios } from '@/utils/request'
import VueCookies from 'vue-cookies'
import vueRouter from '@/router'
import { getSign } from '@/utils/sign/getSign'

const domainName = 'https://wa.ishizhi.cn/api-gateway/jpaas-jags-server/interface'
// const domainName = '/api-gateway/jpaas-jags-server/interface'

// 信息资源库目录id列表
const jirdCatalogIdList = {
  video: '3486ca2c34724c8d9c6cda955c11ca1a',
  audio: '52ed4e02f9ca4431b354ca8887dafbe9',
  bookReview: '4b8a72b876ce44469ade79f0f70c899d'
}

// 检索分类id列表
const jsearchCatalogIdList = {
  video: '223652ea78524d1ba3f686ecd3804715',
  audio: '48479c3cbe1849af9a4197fbba130410',
  bookReview: '4d60712ef4fd43d0a4238020b0cb0c5b'
}

// 分类名称列表
const catalogNameList = {
  video: '视频',
  audio: '音频',
  bookReview: '书评'
}

/**
 * 请求接口
 * @param {Object} param 请求参数
 * @param {string} param.interfaceId 接口唯一标识
 * @param {Object} [param.params={}] 请求参数
 * @param {Object} [param.header={}] 请求头
 * @param {string} [param.appId='bjszs-web'] 应用唯一标识
 */
function requestInterface (param = {
  interfaceId: '',
  params: {},
  header: {},
  appId: 'bjszs-web'
}) {
  return new Promise((resolve, reject) => {
    if (!param.interfaceId) {
      reject(new Error('缺少interface_id'))
    }

    let headerData = {
      'access-token': VueCookies.get('access-token') || '',
      origin: '0'
    }

    if (typeof param.header === 'object') {
      headerData = {
        ...headerData,
        ...param.header
      }
    }

    const data = typeof param.params === 'object' ? JSON.stringify(param.params) : '{}'
    const timestamp = new Date().getTime()

    const paramsData = {
      app_id: param.appId || 'bjszs-web',
      interface_id: `${param.interfaceId}`,
      version: '1.0',
      biz_content: data,
      charset: 'UTF-8',
      timestamp,
      origin: '0',
      header: JSON.stringify(headerData)
    }

    getSign(paramsData).then(signRes => {
      return axios({
        url: `${domainName}/gateway`,
        method: 'post',
        data: {
          ...paramsData,
          sign: signRes
        }
      })
    }).then(res => {
      if (!res.success) reject(res.msg)
      const data = JSON.parse(res.data)
      if (data.code === '250') {
        if (vueRouter.currentRoute.name !== 'index') {
          vueRouter.replace({
            path: '/Login',
            query: {
              redirect: vueRouter.currentRoute.fullPath
            }
          })
        }
      }
      resolve(data)
    }).catch(() => {
      reject(new Error('请求接口失败'))
    })
  })
}

/**
 * 获取信息资源
 * @param {string} type 类型
 * @example
 * // 视频数据
 * getJirdInfos('video')
 * @example
 * // 音频数据
 * getJirdInfos('audio')
 * @example
 * // 书评数据
 * getJirdInfos('bookReview')
 */
function getJirdInfos (type = 'video') {
  return new Promise((resolve, reject) => {
    requestInterface({
      appId: 'jird',
      interfaceId: 'jirdGetInfoByCatalog',
      params: {
        appkey: '4a0c315e5c6b9661dba13e50cff57ba15be94a6eadb56295e9d7a7ed9357623e8307f31b192281831e9f6dc0b6a1f308',
        catalogId: jirdCatalogIdList[type],
        pageIndex: 1,
        pageSize: 50
      }
    }).then(uuidRes => {
      if (!uuidRes.success) return

      const uuidList = []
      uuidRes.data.infos.forEach(item => {
        uuidList.push(item.uuid)
      })

      return requestInterface({
        appId: 'jird',
        interfaceId: 'jirdGetInfos',
        params: {
          appkey: '4a0c315e5c6b9661dba13e50cff57ba15be94a6eadb56295e9d7a7ed9357623e8307f31b192281831e9f6dc0b6a1f308',
          uuids: uuidList.join(',')
        }
      })
    }).then(res => {
      if (!res.success) return
      resolve(res.data)
    }).catch((err) => {
      reject(new Error(err))
    })
  })
}

/**
 * 检索信息资源
 * @param {Object} param 参数
 * @param {string} param.type='video' 资源类型
 * @param {Object} param.params 请求参数
 */
function searchJirdInfos (param = {
  type: 'video',
  params: {}
}) {
  return new Promise((resolve, reject) => {
    requestInterface({
      appId: 'jsearch',
      interfaceId: 'appinfo',
      params: {
        serviceId: '21449b80cc484ffdb6fd47187f2823a9',
        catalogId: jsearchCatalogIdList[param.type],
        ...param.params
      }
    }).then(res => {
      if (!res.success) return
      let data = res.data.appSearchResultBeanList
      const list = []
      let total = 0
      if (data.length === 0) {
        resolve({
          data: list,
          total
        })
      }

      for (const item of res.data.appSearchResultBeanList) {
        if (item.category.categoryName === catalogNameList[param.type]) {
          total = item.mapSearchResult.totalHits
          const groupData = []
          item.mapSearchResult.items.forEach(it1 => {
            groupData.push(...it1.groupData)
          })

          data = groupData
          break
        }
      }
      data.forEach(item => {
        list.push(item.data)
      })
      resolve({
        data: list,
        total
      })
    }).catch(() => {
      reject(new Error('接口请求失败'))
    })
  })
}

/**
 * 获取加密公钥
 * */
function getPublicKey () {
  return new Promise(resolve => {
    const key = sessionStorage.getItem('_pk_')
    if (key) {
      resolve(key)
      return
    }
    requestInterface({
      interfaceId: 'securitygetPublicKey'
    }).then(res => {
      const data = res.data.resultData
      sessionStorage.setItem('_pk_', data)
      resolve(data)
    })
  })
}

/**
 * 获取AES密钥
 */
function getAesKey () {
  return new Promise((resolve, reject) => {
    const key = sessionStorage.getItem('_aesk_')
    if (key) {
      resolve(key)
      return
    }
    requestInterface({
      appId: 'bjszs-transfer',
      interfaceId: 'getAesPublicKey'
    }).then(res => {
      if (!res.success) resolve('')
      const data = res.data.resultData
      sessionStorage.setItem('_aesk_', data)
      resolve(data)
    }).catch(() => {
      reject(new Error('获取AES公钥失败'))
    })
  })
}

export {
  requestInterface,
  getJirdInfos,
  searchJirdInfos,
  getPublicKey,
  getAesKey
}

<script setup>
import { nextTick, onMounted, ref } from 'vue'
import { get, set, useAnimate, useDraggable, useIntervalFn, useThrottleFn, useWindowSize } from '@vueuse/core'
import { Howl } from 'howler'
import { useNamespace } from '@/hook/useNamespace'
import VueCookies from 'vue-cookies'
import { getPublicKey, requestInterface } from '@/api/wklAPI'
import sm2 from '@/utils/encrypt'

const ns = useNamespace('simpleAudioPlayer')

const props = defineProps({
  /**
   * 数据
   */
  data: {
    type: Object,
    default: () => {
      return {
        imgUrl: ''
      }
    }
  }
})

const emits = defineEmits({
  close: null
})

// 旋转封面节点
const rotatingCoverRef = ref(null)

// 旋转动画
const rotatingAnimate = useAnimate(rotatingCoverRef, [
  { transform: 'rotate(0deg)' },
  { transform: 'rotate(360deg)' }
], {
  immediate: false,
  persist: true,
  duration: 15000,
  iterations: Infinity
})

// 浏览器
const windowSize = useWindowSize()

// 播放器节点
const playerRef = ref(null)

// 拖动位置
const {
  style: draggableStyle
} = useDraggable(playerRef, {
  initialValue: {
    x: get(windowSize.width) - 175,
    y: get(windowSize.height) - 200
  }
})

// 播放器配置
const Player = ref({
  playing: false,
  controller: undefined,
  current: 0
})

onMounted(() => {
  if (props.data?.fileName) {
    const player = get(Player)
    getMediaUrl().then(() => {
      init()
      nextTick(() => {
        player.controller?.play()
        player.playing = true
        rotatingAnimate.play()
      })
    })
  }
})

// 音频路径
const audioUrl = ref('')

/**
 * 获取媒体资源的链接
 */
function getMediaUrl () {
  const data = props.data
  if (!data.fileName) return
  set(audioUrl, '')

  return getPublicKey().then(key => {
    const query = sm2.encrypt(JSON.stringify({
      token: VueCookies.get('access-token'),
      fileName: data.fileName,
      mediaId: data.mediaResourceId,
      mediaType: '2'
    }), key)
    const url = `/playPc/${query}`
    set(audioUrl, url)
  })
}

/**
 * 初始化
 */
function init() {
  const player = get(Player)
  player.controller?.stop()
  player.controller = undefined
  if (!get(audioUrl)) return
  player.controller = new Howl({
    src: [get(audioUrl)],
    html5: true,
    format: ['flac', 'mp3', 'wav'],
    onplay() {
      setPlaybackHistory.resume()
      setCurrentProgress.resume()
    },
    onpause() {
      setPlaybackHistory.pause()
      setCurrentProgress.pause()
    },
    onend() {
      requestInterface({
        interfaceId: 'mediaPlayadd',
        params: {
          mediaResourceTopicId: props.data.mediaResourceTopicId,
          playAssemble: props.data.diversityNo,
          playProgressTime: player.controller.duration(),
          mediaType: '2',
          mediaResourceId: props.data.mediaResourceId
        }
      })
      player.playing = false
      rotatingAnimate.pause()
    },
    onstop() {
      requestInterface({
        interfaceId: 'mediaPlayadd',
        params: {
          mediaResourceTopicId: props.data.mediaResourceTopicId,
          playAssemble: props.data.diversityNo,
          playProgressTime: player.current,
          mediaType: '2',
          mediaResourceId: props.data.mediaResourceId
        }
      })
      player.playing = false
      rotatingAnimate.pause()
    }
  })
}

// 设置当前进度
const setCurrentProgress = useIntervalFn(() => {
  const player = get(Player)
  player.current = player.controller.seek()
}, 500, {
  immediate: false
})

/**
 * 设置播放历史
 */
const setPlaybackHistory = useIntervalFn(() => {
  requestInterface({
    interfaceId: 'mediaPlayadd',
    params: {
      mediaResourceTopicId: props.data.mediaResourceTopicId,
      playAssemble: props.data.diversityNo,
      playProgressTime: get(Player).current,
      mediaType: '2',
      mediaResourceId: props.data.mediaResourceId
    }
  })
}, 3500, {
  immediate: false
})

/**
 * 播放
 */
const play = useThrottleFn(() => {
  const player = get(Player)
  if (player.playing || typeof player.controller === 'undefined') return
  player.controller?.play()
  player.playing = true
  rotatingAnimate.play()
}, 500)

/**
 * 暂停
 */
const pause = useThrottleFn(() => {
  const player = get(Player)
  if (!player.playing || typeof player.controller === 'undefined') return
  player.controller?.pause()
  player.playing = false
  rotatingAnimate.pause()
}, 500)

/**
 * 关闭
 */
function close() {
  const player = get(Player)
  if (typeof player.controller !== 'undefined') {
    player.controller?.stop()
  }
  emits('close')
}
</script>
<!-- 简易音频播放器 -->
<template>
  <div
    :class="ns.b()"
    :style="draggableStyle"
    ref="playerRef">
    <div
      :class="ns.e('closeButton')"
      @click="close">
      <a-icon
        :class="ns.e('close')"
        type="close-circle"/>
    </div>
    <div :class="ns.e('playButton')">
      <a-icon
        :class="ns.e('play')"
        v-show="!Player.playing"
        type="play-circle"
        theme="filled"
        @click="play"/>
      <a-icon
        :class="ns.e('pause')"
        v-show="Player.playing"
        type="pause-circle"
        theme="filled"
        @click="pause"/>
    </div>
    <div :class="ns.e('coverContainer')" ref="rotatingCoverRef">
      <img :class="ns.e('cover')" :src="props.data.imgUrl" alt="">
    </div>
  </div>
</template>

<style scoped lang="less">
.wa-simpleAudioPlayer {
  position: fixed;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  border-radius: 25px;
  background-color: white;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  padding: 0 5px;

  &__coverContainer {
    display: flex;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    overflow: hidden;
    margin-right: 8px;
  }

  &__cover {
    width: auto;
    height: 100%;
  }

  &__playButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    cursor: pointer;
    margin-right: 8px;
  }

  &__play,
  &__pause {
    font-size: 24px;
    user-select: none;
    color: #cc0000;
  }

  &__closeButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    cursor: pointer;
  }

  &__close {
    font-size: 20px;
    user-select: none;
    color: #333333;
  }
}
</style>
